import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore'; // Import necessary Firestore functions

const SubscriptionEnquiry = ({db}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      // Reference to the "subscribe-form-submissions" collection
      const collectionRef = collection(db, 'subscribe-form-submissions');
      
      // Add document to Firestore
      await addDoc(collectionRef, {
        name,
        email,
        company,
        subscribedAt: new Date(),
      });

      // Update message and clear form
      setMessage('Thank you. We have received your enquiry. We will get back to you.');
      setName('');
      setCompany('');
      setEmail('');
    } catch (error) {
      setMessage('Error submitting form. Please try again later.');
      console.error('Error writing to Firestore:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
		<main className="main">

    <div className="subscribe-form-container">
      <h2>Subscription Enquiry</h2>
			<p>To subscribe, leave us your contact details here. We will be in touch to arrange the subscription.</p>
      <form className="subscribe-form" onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Your name"
          />
        </label>
        <label>
          Company:
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
            placeholder="Your company"
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Your email"
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Subscribe'}
        </button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
		</main>
  );
};

export default SubscriptionEnquiry;
