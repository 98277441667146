import React, { useState, useEffect } from 'react';
import { useParams , Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Register from './Register'
import EmailLinkAuth from './EmailLinkAuth'

function PostPage({db, user}) {
	const [postContent, setPostContent] = useState("");
	const [postTitle, setPostTitle] = useState("");
	const [postAuthor, setPostAuthor] = useState("");
	const [postDate, setPostDate] = useState("");
	const [userIsPaidSubscriber, setUserIsPaidSubscriber] = useState(false);
	const [isSubscriberOnly, setIsSubscriberOnly] = useState(false);
	const [premiumAvailable, setPremiumAvailable] = useState(false);
	const {urlStub} = useParams();

	useEffect(() => {
		async function fetchPost() {
			const postsRef = collection(db, 'posts');
			const q = query(postsRef, where('url-index', '==', urlStub));
			const querySnapshot = await getDocs(q);

			if(!querySnapshot.empty) {
				const postDoc = querySnapshot.docs[0].data();
				setIsSubscriberOnly(postDoc.subscriberOnly);
				setPremiumAvailable(postDoc.premiumAvailable);
				setUserIsPaidSubscriber(false);
				if(user) {
					setPostContent(postDoc.registeredContent);
				}
				else {
					setPostContent(postDoc.openContent);
				}
					setPostTitle(postDoc.title);
					setPostAuthor(postDoc.author);
					setPostDate(postDoc.postDate?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', }));
			} else {
				setPostContent('Post not found');
			}
		}

		fetchPost();
	}, [db, urlStub, user]);


	if(false && isSubscriberOnly && (!user || !user.isPaidSubscriber)) {
		return(
			<div className="article">
			<h1 className="title">Subscriber-Only Content</h1>
			<div className="metaData">
			<span className="author">{postAuthor}</span>
			<span className="date">{postDate}</span>
			</div>
			<p>This content is available only to paid subscribers.</p>
			{!user && <p>Please <Link to="/login">log in</Link> if you're already a subscriber.</p> }
				{user && !user.isPaidSubscriber && <p>To access this content, please <Link to="/subscribe">subscribe</Link>.</p>}
				</div>
			);}




			return (
		<main className="main">
				<article className="article">
				<h1 className="title">{postTitle}</h1>
				<div className="content" dangerouslySetInnerHTML={{__html: postContent}} />
				<div className="metaData">
				<p><span className="author">{postAuthor}</span></p>
				<span className="date">{postDate}</span>
				</div>
				{
					!user &&
					<div style={{marginTop: '30px', padding: '20px', backgroundColor: '#ddd9d9', color: '#383838'}}>

					<h2>Register to read all articles for free.</h2>
					<p>You will receive the DCM Insider newsletter by email. You will be able to access this article and all our other archived newsletter content online. You can unsubscribe at any time.</p>
					<Register />

					<p>Already registered? Get your sign in link below.</p>
					{ /*<p>Full reports are available with our premium subscriptions. Our premium subscription is US$4,000/month. <a href="/subscription-enquiry" style={{color: "gray"}}>Click here to subscribe.</a></p> */ }
				<EmailLinkAuth />
					</div>
				}
				</article>
				</main>
			);
	}

	export default PostPage;

