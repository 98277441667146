import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const RecentPosts = ({db}) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        // Create a query to get the 10 most recent posts
        const postsQuery = query(
          collection(db, 'posts'), 
          orderBy('postDate', 'desc'), 
          limit(10)
        );

        // Execute the query
        const querySnapshot = await getDocs(postsQuery);
        
        // Map the documents to an array of post objects
        const fetchedPosts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setPosts(fetchedPosts);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching recent posts:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchRecentPosts();
  }, []);

  if (loading) {
    return <div>Loading recent posts...</div>;
  }

  if (error) {
    return <div>Error loading posts: {error.message}</div>;
  }

  return (
    <div className="recent-posts">
      {posts.map(post => (
        <p key={post.id}>
          <Link to={`/${post['url-index']}`}>
            {post.title}
          </Link>
        </p>
      ))}
    </div>
  );
};

export default RecentPosts;
