import React, { useState } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { getFirestore, doc, addDoc, collection, serverTimestamp } from 'firebase/firestore';

const Register = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');

    // Basic validation
    if (!email || !fullName || !company || !position) {
      setError('Please fill in all fields');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      const auth = getAuth();
      const db = getFirestore();
      
      // Configuration for email link
      const actionCodeSettings = {
        url: window.location.origin + '/complete-signup',
        handleCodeInApp: true,
      };

      // Send sign-in link to email
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);

      // Prepare user data for Firestore
      const userData = {
        email,
        fullName,
        company,
        position,
        createdAt: serverTimestamp(),
        status: 'pending' // You can use this to track signup completion
      };

      // Save signup details to localStorage for later retrieval
      localStorage.setItem('emailForSignUp', email);
      // localStorage.setItem('signupDetails', JSON.stringify(userData));

      // Optionally, create a preliminary document in Firestore
      // Note: This document will be incomplete until email verification
      // await setDoc(doc(db, 'users', email), userData);
      await addDoc(collection(db, 'users'), userData);

      setSuccess(true);
    } catch (error) {
      console.error('Signup error:', error);
      setError('Failed to send sign-in link. Please try again.');
    }
  };

  if (success) {
    return (
      <div className="signup-container">
        <div className="signup-card">
          <h2>Check Your Email</h2>
          <p>A sign-in link has been sent to {email}. Please check your inbox to complete registration.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="signup-container">
      <div className="signup-card">
        <form onSubmit={handleSignup} className="signup-form">
          {error && (
            <div className="error-message">
              {error}
            </div>
          )}
          
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Your full name"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder="Your company name"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="position">Position</label>
            <input
              type="text"
              id="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              placeholder="Your job position"
              required
            />
          </div>
          
          <button type="submit" className="signup-button">
            Send Sign-in Link
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
