import React from 'react';
import {Link} from 'react-router-dom';
import RecentPosts from '../RecentPosts'

function HomePage({user, db}){
	return (
		<div className="homepage-background">
		<main className="main homepg">
				<div style={{paddingTop: '50px', paddingBottom: '50px'}} className="article">
				<h1 style={{marginBottom: '50px'}} className="title">Welcome to DCM Insider</h1>


		{/*
		<p>News and data for debt capital markets professionals.</p>
		*/}



<h2 style={{marginTop: '60px', marginBottom: '30px'}}>Recent reports</h2>

		<RecentPosts db={db} />
		{/*
		{user ? (
			<p>Welcome, {user.email}! {!user.isPaidSubscriber && <Link to='/subscribe'>Subscribe</Link>}</p>) : (
				<p><Link to="login">Log in</Link> or <Link to="/subscribe">Subscribe</Link></p>
			)}
			*/}
		</div>
	</main>
		</div>
	);
}

export default HomePage;
