import React, { useState, useEffect } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, updateProfile } from 'firebase/auth';
// import { getFirestore, doc, updateDoc, serverTimestamp } from 'firebase/firestore';

const CompleteSignUp = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const completeSignup = async () => {
      const auth = getAuth();
      // const db = getFirestore();

      // Check if this is a sign-in with email link
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Get email from localStorage
        let userEmail = localStorage.getItem('emailForSignUp');
        localStorage.removeItem('emailForSignUp');

        // If email is not in localStorage, prompt user
        if (!userEmail) {
          userEmail = window.prompt('Please provide your email for confirmation');
        }

        try {
          // Complete the sign-in
          const result = await signInWithEmailLink(auth, userEmail, window.location.href);
          
          // Retrieve signup details from localStorage
          //const signupDetails = JSON.parse(localStorage.getItem('signupDetails'));
          
          //if (signupDetails) {
           // const { fullName, company, position } = signupDetails;

            // Update user profile
//            await updateProfile(result.user, {
//              displayName: fullName
//            });

            // Update Firestore document
//            const userDocRef = doc(db, 'users', userEmail);
//            await updateDoc(userDocRef, {
              // status: 'active',
              // emailVerified: true,
              // updatedAt: serverTimestamp()
            // });

            // Clear localStorage
            //localStorage.removeItem('emailForSignUp');
            //localStorage.removeItem('signupDetails');

            // Redirect or set state for successful signup
            window.location.href = '/'; // Adjust redirect as needed
          //}
        } catch (error) {
          console.error('Signup completion error:', error);
          setError('Failed to complete signup. Please try again.');
          setIsLoading(false);
        }
      } else {
        setError('Invalid signup link');
        setIsLoading(false);
      }
    };

    // Only run if not in a server-side rendering context
    if (typeof window !== 'undefined') {
      completeSignup();
    }
  }, []);

  if (isLoading) {
    return (
		<main className="main">
      <div className="complete-signup-container">
        <div className="loading-spinner"></div>
        <p>Completing your signup...</p>
      </div>
			</main>
    );
  }

  if (error) {
    return (
		<main className="main">
      <div className="complete-signup-container">
        <div className="error-card">
          <h2>Signup Error</h2>
          <p>{error}</p>
          <button 
            onClick={() => window.location.href = '/signup'}
            className="retry-button"
          >
            Try Again
          </button>
        </div>
      </div>
			</main>
    );
  }

  return null;
};

export default CompleteSignUp;
