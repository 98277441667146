import React, { useState } from 'react';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const EmailLinkAuth = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  // Configuration for email link sign-in
  const actionCodeSettings = {
    url: window.location.href, // Send user back to current page
    handleCodeInApp: true,
  };

  const handleSendLink = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Store the email in local storage so we can retrieve it during sign-in
      window.localStorage.setItem('emailForSignIn', email);

      // Send sign-in link to email
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      setEmailSent(true);
    } catch (error) {
      setError(error.message);
      console.error('Error sending email link:', error);
    }
  };

  const handleSignIn = async () => {
    try {
      // Check if this is a sign-in with email link
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Retrieve the email we saved earlier
        let email = window.localStorage.getItem('emailForSignIn');
        
        // If email isn't in local storage, prompt user
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        // Complete sign-in
        const result = await signInWithEmailLink(auth, email, window.location.href);
        
        // Remove the email from local storage
        window.localStorage.removeItem('emailForSignIn');

        // Navigate back to the original page
        navigate(window.localStorage.getItem('originalPath') || '/');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error signing in:', error);
    }
  };

  // Run sign-in check when component mounts
  React.useEffect(() => {
    // Store the current path before potential navigation
    window.localStorage.setItem('originalPath', window.location.pathname);

    // Check if this is a sign-in link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      handleSignIn();
    }
  }, []);

  return (
    <div className="email-link-auth">
      {!emailSent ? (
        <form onSubmit={handleSendLink} className="email-link-form">
          <input 
            type="email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="email-input"
          />
          <button 
            type="submit" 
            className="submit-button"
          >
            Send Sign-In Link
          </button>
        </form>
      ) : (
        <div className="email-sent-message">
          Check your email for the sign-in link!
        </div>
      )}
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
    </div>
  );
};

export default EmailLinkAuth;
